var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-white p-4 text-gray-500 my-4 rounded-lg shadow-sm"
  }, [_c('div', {
    staticClass: "py-3 text-gray-800"
  }, [_c('div', {
    staticClass: "ml-1 no-underline flex justify-start items-center text-gray-500"
  }, [_c('router-link', {
    staticClass: "text-gray-500 no-underline flex items-center list-none hover:no-underline hover:text-gray-500",
    attrs: {
      "to": {
        path: "/setting/otp-management"
      }
    }
  }, [_vm._v(" Config Management ")]), _vm._v(" "), _c('i', {
    staticClass: "fa fa-angle-right ml-2 text-gray-500 mr-2",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Edit ")], 1), _c('h5', {
    staticClass: "mt-1 text-2xl"
  }, [_vm._v(" Config Management")])])]), _c('div', {
    staticClass: "bg-white mt-3 flex-col rounded-lg shadow-sm p-4"
  }, [_c('h4', {
    staticClass: "block w-full text-xl font-bold mb-4"
  }, [_vm._v("Edit Config Management Details")]), _c('div', {
    staticClass: "relative inline-block text-left w-1/2 ml-2"
  }, [_c('label', [_vm._v("OTP Resend Limit")]), _c('button', {
    ref: "dropdownButtonResendLimit",
    staticClass: "inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleDropdown('resendLimit');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedDataResendLimit || 'Select Resend Limit') + " "), _c('svg', {
    staticClass: "ml-2 -mr-1 h-5 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M19 9l-7 7-7-7"
    }
  })])]), _vm.isOpen.resendLimit ? _c('div', {
    ref: "dropdownMenuResendLimit",
    staticClass: "absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  }, [_c('div', {
    staticClass: "p-1"
  }, _vm._l(_vm.resendLimitOptions, function (option) {
    return _c('label', {
      key: option.value,
      staticClass: "flex items-center p-2 cursor-pointer hover:bg-gray-100"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedDataResendLimit,
        expression: "selectedDataResendLimit"
      }],
      staticClass: "form-radio h-4 w-4 text-blue-600",
      attrs: {
        "type": "radio"
      },
      domProps: {
        "value": option.value,
        "checked": _vm._q(_vm.selectedDataResendLimit, option.value)
      },
      on: {
        "change": [function ($event) {
          _vm.selectedDataResendLimit = option.value;
        }, function ($event) {
          return _vm.handleChange('resendLimit');
        }]
      }
    }), _c('span', {
      staticClass: "ml-2 text-gray-700"
    }, [_vm._v(_vm._s(option.text))])]);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "relative inline-block text-left w-1/2 ml-2 mt-4"
  }, [_c('label', [_vm._v("OTP Time Limit")]), _c('button', {
    ref: "dropdownButtonTimeLimit",
    staticClass: "inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleDropdown('timeLimit');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedDataTimeLimit + ' minute' || 'Select Time Limit') + " "), _c('svg', {
    staticClass: "ml-2 -mr-1 h-5 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M19 9l-7 7-7-7"
    }
  })])]), _vm.isOpen.timeLimit ? _c('div', {
    ref: "dropdownMenuTimeLimit",
    staticClass: "absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  }, [_c('div', {
    staticClass: "p-1"
  }, _vm._l(_vm.timeLimitOptions, function (option) {
    return _c('label', {
      key: option.value,
      staticClass: "flex items-center p-2 cursor-pointer hover:bg-gray-100"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedDataTimeLimit,
        expression: "selectedDataTimeLimit"
      }],
      staticClass: "form-radio h-4 w-4 text-blue-600",
      attrs: {
        "type": "radio"
      },
      domProps: {
        "value": option.value,
        "checked": _vm._q(_vm.selectedDataTimeLimit, option.value)
      },
      on: {
        "change": [function ($event) {
          _vm.selectedDataTimeLimit = option.value;
        }, function ($event) {
          return _vm.handleChange('timeLimit');
        }]
      }
    }), _c('span', {
      staticClass: "ml-2 text-gray-700"
    }, [_vm._v(_vm._s(option.text))])]);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "relative inline-block text-left w-1/2 ml-2 mt-4"
  }, [_c('label', [_vm._v("Deauthorization Attempt Limit")]), _c('button', {
    ref: "dropdownButtonOtpTimeLimit",
    staticClass: "inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleDropdown('otpTimeLimit');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedDataDeauthDeviceLimit + ' per week' || 'Select OTP Time Limit') + " "), _c('svg', {
    staticClass: "ml-2 -mr-1 h-5 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M19 9l-7 7-7-7"
    }
  })])]), _vm.isOpen.otpTimeLimit ? _c('div', {
    ref: "dropdownMenuOtpTimeLimit",
    staticClass: "absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  }, [_c('div', {
    staticClass: "p-1"
  }, _vm._l(_vm.otpTimeLimitOptions, function (option) {
    return _c('label', {
      key: option.value,
      staticClass: "flex items-center p-2 cursor-pointer hover:bg-gray-100"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedDataDeauthDeviceLimit,
        expression: "selectedDataDeauthDeviceLimit"
      }],
      staticClass: "form-radio h-4 w-4 text-blue-700",
      attrs: {
        "type": "radio"
      },
      domProps: {
        "value": option.value,
        "checked": _vm._q(_vm.selectedDataDeauthDeviceLimit, option.value)
      },
      on: {
        "change": [function ($event) {
          _vm.selectedDataDeauthDeviceLimit = option.value;
        }, function ($event) {
          return _vm.handleChange('otpTimeLimit');
        }]
      }
    }), _c('span', {
      staticClass: "ml-2 text-gray-700"
    }, [_vm._v(_vm._s(option.text))])]);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "relative inline-block text-left w-1/2 ml-2 mt-4"
  }, [_c('label', [_vm._v("Device Limit")]), _c('button', {
    ref: "dropdownButtonMaxDeviceLimit",
    staticClass: "inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleDropdown('maxDeviceLimit');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedDataMaxDeviceLimit || 'Select Device Limit') + " "), _c('svg', {
    staticClass: "ml-2 -mr-1 h-5 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M19 9l-7 7-7-7"
    }
  })])]), _vm.isOpen.maxDeviceLimit ? _c('div', {
    ref: "dropdownMenuMaxDeviceLimit",
    staticClass: "absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  }, [_c('div', {
    staticClass: "p-1"
  }, _vm._l(_vm.deviceLimitOptions, function (option) {
    return _c('label', {
      key: option.value,
      staticClass: "flex items-center p-2 cursor-pointer hover:bg-gray-100"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedDataMaxDeviceLimit,
        expression: "selectedDataMaxDeviceLimit"
      }],
      staticClass: "form-radio h-4 w-4 text-blue-600",
      attrs: {
        "type": "radio"
      },
      domProps: {
        "value": option.value,
        "checked": _vm._q(_vm.selectedDataMaxDeviceLimit, option.value)
      },
      on: {
        "change": [function ($event) {
          _vm.selectedDataMaxDeviceLimit = option.value;
        }, function ($event) {
          return _vm.handleChange('maxDeviceLimit');
        }]
      }
    }), _c('span', {
      staticClass: "ml-2 text-gray-700"
    }, [_vm._v(_vm._s(option.text))])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "flex justify-start mt-4"
  }, [_c('button', {
    staticClass: "mr-2 bg-blue-700 p-1 px-2 rounded-lg text-white text-sm focus:no-underline focus:outline-none",
    on: {
      "click": _vm.handleSave
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "border p-1 px-2 rounded-lg focus:no-underline no-underline list-none focus:outline-none"
  }, [_c('router-link', {
    staticClass: "text-gray-900 hover:text-gray-900 no-underline flex list-none hover:no-underline",
    attrs: {
      "to": {
        path: "/setting/otp-management"
      }
    }
  }, [_vm._v(" Cancel ")])], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }