import { render, staticRenderFns } from "./editConfigManagement.vue?vue&type=template&id=323e3a20&scoped=true&"
import script from "./editConfigManagement.vue?vue&type=script&lang=js&"
export * from "./editConfigManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323e3a20",
  null
  
)

export default component.exports