<template>
    <div>
        <div class="bg-white p-4 text-gray-500 my-4 rounded-lg shadow-sm">
            <div class="py-3 text-gray-800">
                    <div class="ml-1 no-underline flex justify-start items-center text-gray-500"> 
                        <router-link class="text-gray-500 no-underline flex items-center  list-none hover:no-underline hover:text-gray-500"
                         :to="{ path: `/setting/otp-management`}">  Config Management 
                        </router-link> <i class="fa fa-angle-right ml-2 text-gray-500 mr-2" aria-hidden="true"> </i> Edit
                      
                    </div>
                
                <h5 class="mt-1 text-2xl"> Config Management</h5>
                </div>
        </div>

        <div class="bg-white mt-3 flex-col rounded-lg shadow-sm p-4">
            <h4 class="block w-full text-xl font-bold mb-4">Edit Config Management Details</h4>

            <!-- OTP Resend Limit -->
            <div class="relative inline-block text-left w-1/2 ml-2">
                <label>OTP Resend Limit</label>
                <button type="button" @click="toggleDropdown('resendLimit')" ref="dropdownButtonResendLimit"
                    class="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    {{ selectedDataResendLimit || 'Select Resend Limit' }}
                    <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                <!-- Dropdown Menu -->
                <div v-if="isOpen.resendLimit" ref="dropdownMenuResendLimit"
                    class="absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-1">
                        <label v-for="option in resendLimitOptions" :key="option.value"
                            class="flex items-center p-2 cursor-pointer hover:bg-gray-100">
                            <input type="radio" :value="option.value" v-model="selectedDataResendLimit"
                                @change="handleChange('resendLimit')" class="form-radio h-4 w-4 text-blue-600" />
                            <span class="ml-2 text-gray-700">{{ option.text }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="relative inline-block text-left w-1/2 ml-2 mt-4">
                <label>OTP Time Limit</label>
                <button type="button" @click="toggleDropdown('timeLimit')" ref="dropdownButtonTimeLimit"
                    class="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    {{ selectedDataTimeLimit + ' minute' || 'Select Time Limit' }}
                    <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                <!-- Dropdown Menu -->
                <div v-if="isOpen.timeLimit" ref="dropdownMenuTimeLimit"
                    class="absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-1">
                        <label v-for="option in timeLimitOptions" :key="option.value"
                            class="flex items-center p-2 cursor-pointer hover:bg-gray-100">
                            <input type="radio" :value="option.value" v-model="selectedDataTimeLimit"
                                @change="handleChange('timeLimit')" class="form-radio h-4 w-4 text-blue-600" />
                            <span class="ml-2 text-gray-700">{{ option.text }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="relative inline-block text-left w-1/2 ml-2 mt-4">
                <label>Deauthorization Attempt Limit</label>
                <button type="button" @click="toggleDropdown('otpTimeLimit')" ref="dropdownButtonOtpTimeLimit"
                    class="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    {{ selectedDataDeauthDeviceLimit + ' per week' || 'Select OTP Time Limit' }}
                    <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                <div v-if="isOpen.otpTimeLimit" ref="dropdownMenuOtpTimeLimit"
                    class="absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-1">
                        <label v-for="option in otpTimeLimitOptions" :key="option.value"
                            class="flex items-center p-2 cursor-pointer hover:bg-gray-100">
                            <input type="radio" :value="option.value" v-model="selectedDataDeauthDeviceLimit"
                                @change="handleChange('otpTimeLimit')" class="form-radio h-4 w-4 text-blue-700" />
                            <span class="ml-2 text-gray-700">{{ option.text }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="relative inline-block text-left w-1/2 ml-2 mt-4">
                <label>Device Limit</label>
                <button type="button" @click="toggleDropdown('maxDeviceLimit')" ref="dropdownButtonMaxDeviceLimit"
                    class="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    {{ selectedDataMaxDeviceLimit || 'Select Device Limit' }}
                    <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                <div v-if="isOpen.maxDeviceLimit" ref="dropdownMenuMaxDeviceLimit"
                    class="absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-1">
                        <label v-for="option in deviceLimitOptions" :key="option.value"
                            class="flex items-center p-2 cursor-pointer hover:bg-gray-100">
                            <input type="radio" :value="option.value" v-model="selectedDataMaxDeviceLimit"
                                @change="handleChange('maxDeviceLimit')" class="form-radio h-4 w-4 text-blue-600" />
                            <span class="ml-2 text-gray-700">{{ option.text }}</span>
                        </label>
                    </div>
                </div>
                <div class="flex justify-start mt-4">
                    <button class="mr-2 bg-blue-700 p-1 px-2 rounded-lg text-white text-sm focus:no-underline focus:outline-none" @click="handleSave">Save</button>
                    <button class="border p-1 px-2 rounded-lg focus:no-underline no-underline list-none focus:outline-none">
                        <router-link class="text-gray-900 hover:text-gray-900 no-underline flex list-none hover:no-underline "
                         :to="{ path: `/setting/otp-management`}"> Cancel
                        </router-link>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { EventBus } from '../../../event-bus';
export default {
    data() {
        return {
            isOpen: {
                resendLimit: false,
                otpTimeLimit: false,
                maxDeviceLimit: false,
                timeLimit:false
            },
            selectedDataResendLimit: null,
            selectedDataTimeLimit:null,
            selectedDataMaxDeviceLimit: null,
            selectedDataDeauthDeviceLimit: null,
            resendLimitOptions: [
                { value: '1', text: '1' },
                { value: '2', text: '2' },
                { value: '3', text: '3' },
                { value: '4', text: '4' },
                { value: '5', text: '5' },
            ],
            timeLimitOptions: [
                { value: '1', text: '1 minute' },
                { value: '2', text: '2 minute' },
                { value: '3', text: '3 minute' },
                { value: '4', text: '4 minute' },
                { value: '5', text: '5 minute' },
            ],
            otpTimeLimitOptions: [
                { value: '1', text: '1 per week' },
                { value: '2', text: '2 per week' },
                { value: '3', text: '3 per week' },
            ],
            deviceLimitOptions: [
                { value: '1', text: '1' },
                { value: '2', text: '2' },
                { value: '3', text: '3' },
                { value: '4', text: '4' },
                { value: '5', text: '5' },
            ],
        };
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.ManageOtp.isLoading,
            isError: (state) => state.ManageOtp.isError,
            totalRows: (state) => state.ManageOtp.totalRows,
            items: (state) => state.ManageOtp.items,
        }),
    },
    mounted() {
        this.getOtp();
    },
    methods: {
        ...mapActions('ManageOtp', ['fetchManageOtp','updateConfigOtp']),
        getOtp() {
            this.fetchManageOtp().then(response => {
                if (response.data) {
                    const data = response.data.data;

                    const otpResendLimit = data.find(item => item.name === "OTP_RESEND_LIMIT");
                    const otpTimeLimit = data.find(item => item.name === "OTP_TIME_LIMIT");
                    const maxDeviceLimit = data.find(item => item.name === "MAX_DEVICE_LIMIT");
                    const deauthDeviceLimit = data.find(item => item.name === "DEAUTH_DEVICE_LIMIT");

                    if (otpResendLimit) this.selectedDataResendLimit = otpResendLimit.value;
                    if (otpTimeLimit) this.selectedDataTimeLimit = otpTimeLimit.value;
                    if (maxDeviceLimit) this.selectedDataMaxDeviceLimit = maxDeviceLimit.value;
                    if (deauthDeviceLimit) this.selectedDataDeauthDeviceLimit = deauthDeviceLimit.value;
                }
            });
        },
        handleSave() {
            const payload = {                 
                OTP_TIME_LIMIT: Number(this.selectedDataTimeLimit),
                MAX_DEVICE_LIMIT: Number(this.selectedDataMaxDeviceLimit),
                OTP_RESEND_LIMIT: Number(this.selectedDataResendLimit),
                DEAUTH_DEVICE_LIMIT: Number(this.selectedDataDeauthDeviceLimit)
            };
            console.log('checkyaa',payload)
            this.updateConfigOtp(payload).then((response)=> {
                if(response.status=== 200) {
                    EventBus.$emit('show-toast', {
                    message: 'Config management has been updated.',
                    type: 'success',
                });
                this.$router.push({ path: '/setting/otp-management' });
                }
            })
        },
        toggleDropdown(type) {
            this.isOpen[type] = !this.isOpen[type];
            Object.keys(this.isOpen).forEach(key => {
                if (key !== type) this.isOpen[key] = false;
            });

            if (this.isOpen[type]) {
                document.addEventListener('click', this.handleClickOutside);
            } else {
                document.removeEventListener('click', this.handleClickOutside);
            }
        },
        handleChange(type) {
            console.log(`Selected ${type}: ${this[`selectedData${type.charAt(0).toUpperCase() + type.slice(1)}`]}`);
            this.isOpen[type] = false;
            document.removeEventListener('click', this.handleClickOutside);
        },
        handleClickOutside(event) {
            const isClickInside = Object.keys(this.isOpen).some(key => {
                const buttonRef = this.$refs[`dropdownButton${key.charAt(0).toUpperCase() + key.slice(1)}`];
                const menuRef = this.$refs[`dropdownMenu${key.charAt(0).toUpperCase() + key.slice(1)}`];
                return (buttonRef && buttonRef.contains(event.target)) ||
                    (menuRef && menuRef.contains(event.target));
            });

            if (!isClickInside) {
                Object.keys(this.isOpen).forEach(key => {
                    this.isOpen[key] = false;
                });
                document.removeEventListener('click', this.handleClickOutside);
            }
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    }
};
</script>

<style scoped></style>